import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/client/layout.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
		return originalPush.call(this, location).catch(err => err)
	}
const routes = [

    {
        path: '/card_user/login',
        name: 'cardUserLogin',
        component: () => import('../views/card_client/login.vue'),
        meta: {
            title: '登录',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },
    // 原来的
    {
        path: '/card_user/index',
        name: 'cardUserIndex',
        component: () => import('../views/card_client/index.vue'),
        meta: {
            title: '我的',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },
    // {
    //     path: '/card_user/index',
    //     name: 'cardUserIndex',
    //     component: () => import('../views/card_client/new/index.vue'),
    //     meta: {
    //         title: '我的',
    //         keywords: '',
    //         decs: 'index',
    //         keepAlive: false,
    //     },
    // },
    {
        path: '/card_user/addReport',
        name: 'addReport',
        component: () => import('../views/card_client/new/addReport.vue'),
        meta: {
            title: '权益报案',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },
    {
        path: '/card_user/addCure',
        name: 'addCure',
        component: () => import('../views/card_client/new/addCure.vue'),
        meta: {
            title: '治疗材料提交',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },
    {
        path: '/card_user/addHealth',
        name: 'addHealth',
        component: () => import('../views/card_client/new/addHealth.vue'),
        meta: {
            title: '健康报告提交',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },
    {
        path: '/card_user/shareIndex',
        name: 'cardUserShareIndex',
        component: () => import('../views/card_client/shareIndex.vue'),
        meta: {
            title: '我的权益卡',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },
    {
        path: '/card_user/cardDetail',
        name: 'cardUserCardDetail',
        component: () => import('../views/card_client/new/cardDetail.vue'),
        meta: {
            title: '卡片详情',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },
    {
        path: '/card_user/activationCard',
        name: 'cardUserActivationCard',
        component: () => import('../views/card_client/new/activationCard.vue'),
        meta: {
            title: '激活卡片',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },
	{
	    path: '/card_user/saveSignature',
	    name: 'saveSignature',
	    component: () => import('../views/card_client/new/saveSignature.vue'),
	    meta: {
	        title: '激活卡片',
	        keywords: '',
	        decs: 'index',
	        keepAlive: false,
	    },
	},
	{
	    path: '/card_user/saveAudio',
	    name: 'saveAudio',
	    component: () => import('../views/card_client/new/saveAudio.vue'),
	    meta: {
	        title: '激活卡片',
	        keywords: '',
	        decs: 'index',
	        keepAlive: false,
	    },
	},
    {
        path: '/card_user/addUser',
        name: 'cardUserAddUser',
        component: () => import('../views/card_client/new/addUser.vue'),
        meta: {
            title: '新增成员',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },
    {
        path: '/card_user/userList',
        name: 'cardUserUserList',
        component: () => import('../views/card_client/new/userList.vue'),
        meta: {
            title: '家庭成员',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },
    {
        path: '/card_user/customerService',
        name: 'cardUserCustomerService',
        component: () => import('../views/card_client/new/customerService.vue'),
        meta: {
            title: '智能客服',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },
    {
        path: '/card_user/customerServiceRecord',
        name: 'cardUserCustomerServiceRecord',
        component: () => import('../views/card_client/new/customerServiceRecord.vue'),
        meta: {
            title: '智能客服',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },
    {
        path: '/card_user/serviceRecord',
        name: 'cardUserServiceRecord',
        component: () => import('../views/card_client/new/serviceRecord.vue'),
        meta: {
            title: '服务记录',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },
    {
        path: '/card_user/serviceRecordDetail',
        name: 'cardUserServiceRecordDetail',
        component: () => import('../views/card_client/new/serviceRecordDetail.vue'),
        meta: {
            title: '服务记录详情',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },
    {
        path: '/card_user/result',
        name: 'cardUserResult',
        component: () => import('../views/card_client/new/result.vue'),
        meta: {
            title: '查看结果',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },


    {
        path: '/card_user/share',
        name: 'cardUserShare',
        component: () => import('../views/card_client/share.vue'),
        meta: {
            title: '分享',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },


    {
        path: '/card/sign',
        name: 'sign',
        component: () => import('../views/card_client/sign.vue'),
        meta: {
            title: '签名',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },


    {
        path: '/',
        name: 'index',
        // component: () => import('../views/index/index.vue')
        component: () => import('../views/card_client/login.vue'),
    },


    {
        path: '/index',
        name: 'index',
        component: () => import('../views/index/index.vue'),
        meta: {
            title: '客户',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },
    {
        path: '/customerDetail',
        name: 'customerDetail',
        component: () => import('../views/index/customerDetail.vue'),
        meta: {
            title: '客户详情',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },
    {
        path: '/addCustomer',
        name: 'addCustomer',
        component: () => import('../views/index/addCustomer.vue'),
        meta: {
            title: '添加客户',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },
    {
        path: '/editConsultation',
        name: 'editConsultation',
        component: () => import('../views/index/editConsultation.vue')
    },
    {
        path: '/editConsultations',
        name: 'editConsultations',
        component: () => import('../views/index/editConsultations.vue')
    },
    {
        path: '/consultation',
        name: 'consultation',
        component: () => import('../views/index/consultation.vue'),
        meta: {
            title: '问诊',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },
    {
        path: '/zhuanyezixun',
        name: 'zhuanyezixun',
        component: () => import('../views/index/zhuanyezixun.vue')
    },
    {
        path: '/addZyzx',
        name: 'addZyzx',
        component: () => import('../views/index/addZyzx.vue')
    },
    {
        path: '/password',
        name: 'password',
        component: () => import('../views/user/password.vue'),
        meta: {
            title: '修改密码',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },
    {
      path: '/form',
      name: 'form',
      component: () => import('../views/index/form.vue'),
      meta: {
          title: '问卷',
          keywords: '',
          decs: 'index',
          keepAlive: false,
      },
    },
    {
        path: '/my',
        name: 'my',
        component: () => import('../views/user/index.vue'),
        meta: {
            title: '我的',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login/index.vue'),
        meta: {
            title: '登录',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },
    {
        path: '/user/login',
        name: 'userLogin',
        component: () => import('../views/client/login.vue'),
        meta: {
            title: '登录',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },
    {
        path: '/user/business',
        name: 'userBusiness',
        component: Layout,
        children: [
            {
                path: '/user/business',
                name: 'userBusiness1',
                component: () =>import('../views/client/business.vue'),
                meta: {
                    title: '我的问诊',
                    keywords: '',
                    decs: 'index',
                    keepAlive: false,
                },
            }
        ]

    },

    {
        path: '/user/info',
        name: 'userInfo',
        component: () => import('../views/client/info.vue'),
        meta: {
            title: '治疗前准备',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },
    {
        path: '/user/pay',
        name: 'userPay',
        component: () => import('../views/client/pay.vue'),
        meta: {
            title: '支付',
            keywords: '伍月石榴医疗分享',
            decs: 'index',
            keepAlive: false,
        },
    },
    {
        path: '/user/jump',
        name: 'userJump',
        component: () => import('../views/client/jump.vue'),
        meta: {
            title: '正在跳转',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },
    {
        path: '/user/form',
        name: 'userForm',
        component: () => import('../views/client/form.vue'),
        meta: {
            title: '问卷',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },
    {
        path: '/read/form',
        name: 'jForm',
        component: () => import('../views/index/jForm.vue'),
        meta: {
            title: '问卷',
            keywords: '',
            decs: 'index',
            keepAlive: false,
        },
    },



]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
})
export default router
