<!-- 首页 -->
<template>
  <div>
    <router-view />
    <tabbar />
  </div>
</template>

<script >
import tabbar from './tabbar.vue'
export default {
  components: { tabbar },
  data () { return {} },
  methods: {}
}
</script>

<style lang="less" scoped></style>
