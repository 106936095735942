import axios from 'axios'

import {
  removeAuth
} from '@/utils/auth'
import qs from 'qs'
var showLoginMessageBox = false
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
// 创建axios实例
// let hrefs = []
// if (window.location.href.indexOf("index.html") != -1) {
//   hrefs = window.location.href.split('index.html')
// } else {
//   hrefs = window.location.href.split('#')
// }
// let baseURL = hrefs.length > 0 ? hrefs[0] : window.location.href
// baseURL + 'index.php/' 默认请求地址
// process.env.BASE_API 自定义请求地址
console.log(process.env.BASE_API,'process.env.BASE_API')
if(!process.env.BASE_API){
  window.BASE_URL = "/api"
  // window.BASE_URL = "/"
}else{
  window.BASE_URL = process.env.BASE_API
}

axios.defaults.headers.authKey = localStorage.getItem("authKey");
axios.defaults.headers.sessionId = localStorage.getItem("sessionId");
const service = axios.create({
  baseURL: window.BASE_URL, // api 的 base_url
  timeout: 300000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    if (!config.headers['Content-Type'] || config.headers['Content-Type'].indexOf('multipart/form-data') == -1) {
      config.data = qs.stringify(config.data)
    }
    config.headers['authKey']=localStorage.getItem("authKey");
    config.headers['sessionId']=localStorage.getItem("sessionId");
    return config
  },
  error => {
    // Do something with request error
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    /**
     * code为非200是抛错
     */
    const res = response.data
    if (response.status === 200 && response.config.responseType === 'blob') { // 文件类型特殊处理
      return response
    } else if (res.code !== 200) {
      // 101	登录已失效 102	没有权限 103	账号已被删除或禁用
      if (res.code === 101) {

      } else if (res.code === 402) {
        if (res.error && Object.prototype.toString.call(res.error) === '[object Array]') {
          res.error = res.error.reduce(function (prev, cur, index, array) {
            return prev + "\r\n" + cur
          })
        }

      } else {
        if (res.error) {

        }
      }
      return Promise.reject(res.error)
    } else {
      return res
    }
  },
  error => {
    // Message({
    //   message: '网络请求失败，请稍候再试',
    //   type: 'error'
    // })
    return Promise.reject(error)
  }
)

export default service
