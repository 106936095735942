import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'


import cache from '@/utils/cache'
console.log(cache,'cache')
cache.loadingCache()

import Vant from 'vant'
import 'vant/lib/index.css'
Vue.use(Vant)

import { Lazyload } from 'vant';
Vue.use(Lazyload, {
  lazyComponent: true,
});

import { Tabbar, TabbarItem } from 'vant';

Vue.use(Tabbar);
Vue.use(TabbarItem);

Vue.config.productionTip = false
Vue.prototype.$axios = axios
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
