import store from '@/store'
// import Lockr from 'lockr'
import axios from 'axios'

const cache = {
  /**
   * 载入全部登陆信息
   */
  loadingCache: function () {
    // console.log(Lockr,'Lockr')
    // if (Lockr.get('authKey') && !axios.defaults.headers.authKey) {
    //   /** 将用户信息放入缓存 */
    //   const userInfo = Lockr.get('loginUserInfo')
    //   if (userInfo) {
    //     store.commit('SET_USERINFO', userInfo)
    //   }
    //   const authList = Lockr.get('authList')
    //   if (authList) {
    //     store.commit('SET_CRM', authList.crm)
    //     store.commit('SET_BI', authList.bi)
    //     store.commit('SET_MANAGE', authList.manage)
    //   }
    // }
    if (localStorage.getItem('authKey') && !axios.defaults.headers.authKey) {
      /** 将用户信息放入缓存 */
      const userInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
      if (userInfo) {
        store.commit('SET_USERINFO', userInfo)
      }
      const authList = JSON.parse(localStorage.getItem('authList'))
      if (authList) {
        store.commit('SET_CRM', authList.crm)
        store.commit('SET_BI', authList.bi)
        store.commit('SET_MANAGE', authList.manage)
      }
    }
    store.commit('SET_APPNAME', localStorage.getItem('systemName'))
    store.commit('SET_APPLOGO', localStorage.getItem('systemLogo'))
    store.dispatch('SystemLogoAndName')
  },
  /**
   * 请求和更新登录缓存
   */
  updateAxiosCache: function () {
    axios.defaults.headers.authKey = localStorage.getItem('authKey')
    axios.defaults.headers.sessionId = localStorage.getItem('sessionId')
    store.dispatch("GetUserInfo")
  },
  /**
   * 移除登录信息
   * @param {*}  
   */
  rmAxiosCache: function () {
    localStorage.removeItem('authKey')
    localStorage.removeItem('sessionId')
  }
}

export default cache
