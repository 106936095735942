<template>
  <div class="footer">
    <ul class="tabber">
      <li :class="[itemType == 1?'itemType':'']"><div @click="jump(1)"><van-icon name="column"  size="26"/><div>问诊</div></div></li>
      <li :class="[itemType == 2?'itemType':'']"><div @click="jump(2)"><van-icon name="manager" size="26" /><div>我的</div></div></li>
    </ul>
  </div>
</template>

<script >
export default {
  data () { 
    return {
      itemType:1,
    } 
  },
  props:{
    
  },
  methods: {
    jump(a){
      this.itemType = a;
      console.log(this.itemType,'====',this.$route.path)
      if(a == 1){
        this.$router.push({
          path:"/user/business"
        })
      }else{
        this.$router.push({
          path:"/user/index"
        })
      }
      
    },
  },
  created() {
    if(this.$route.name == "userIndex1"){
      this.itemType = 2;
    }else {
      this.itemType = 1;
    }
  }
}
</script>

<style lang="less" scoped>
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.tabber {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: #fff;
}
.tabber li {
  flex: 1;

}
.tabber li a{
  display: block;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  text-decoration: none;
  color: #C5C5C5;
}
/deep/ .tabber li i{
  font-size: 28px;
}
.tabber li a:hover {
  color: #4787F0;
}
.tabber .itemType div{
  color: #4787F0;
}
// .tabber li:nth-child(3) {
//   width: 80px;
//   height: 80px;
//   padding-top: 20px;
//   border-radius: 50%;
//   background-color: #fff;
//   text-align: center;

// }
</style>
